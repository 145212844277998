<template>
  <div class="ma-3">

    <Snackbar ref="snackbar"></Snackbar>

    <div class="Withdraws">
      <h3>Выводы архив</h3>
    </div>

    <div>
      <v-data-table
          :items="conclusion"
          :headers="headers"
          class="elevation-1"
          sort-by="id"
          sort-desc
          show-expand
          :footer-props="{
                showFirstLastPage: true,
                showCurrentPage:true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',

                'items-per-page-text':'Показать на странице',
                'items-per-page-all-text':'Все',
                'items-per-page-options':
[
  50,
  100,
  200,
  -1
]
          }"
      >

        <template v-slot:item.id="{ item }">  {{item.id}}  </template>
        <template v-slot:item.status="{ item }">  {{item.status}}  </template>
        <template v-slot:item.nickname="{ item }">  {{item.user? item.user.nickname:""}}  </template>
        <template v-slot:item.userid="{ item }">  {{item.user? item.user.id:""}}  </template>
        <template v-slot:item.created_at="{ item }">  {{ item.created_at? new Date(Date.parse(item.created_at)).toLocaleString():""  }}  </template>

        <template v-slot:item.crypto="{ item }">  {{item.cryptocurrency.title}}  </template>
        <template v-slot:item.amount="{ item }"> {{ item.cryptocurrency_amount }}</template>

        <template v-slot:item.commission="{ item }"> {{ item.wallet.cryptonetwork.commission }}</template>


        <template v-slot:item.wallet="{ item }">  <WalletView  :wallet="item.wallet.wallet_hash"  ></WalletView>  </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length-2">


            <span> {{ `Причина: ${ item.cancellation_message? item.cancellation_message : "--"}`}} </span><br>

            <span> {{ `Баланс до: ${item.balance_before.toLocaleString('ru-RU', {style: 'currency', currency: 'USD'})}`}} </span><br>
            <span> {{ `Баланс после: ${item.balance_after.toLocaleString('ru-RU', {style: 'currency', currency: 'USD'})}`}} </span>
          </td>
        </template>



      </v-data-table>
    </div>
  </div>
</template>

<script>

import gql from "graphql-tag";

import Snackbar from "../components/controls/Snackbar.vue";
//import TextInputDialog from "../components/dialogs/TextInputDialog.vue";

import {fetcher, getHeaders} from "../store";
import WalletView from "../components/controls/WalletView.vue";


export default {
  name: 'ExchangeConclusionHistory',

  components: {WalletView, Snackbar },

  apollo: {
    $subscribe: {
      exchange_withdrawals: {
        query: gql`

subscription MyQuery {
  exchange_withdrawals(where: {status: {_in: ["Отмена","Успех"]}}) {

    cryptocurrency_amount

    cryptocurrency {
      title
    }

    balance_after
    balance_before

    created_at
    status

    id
    cancellation_message
    updated_at
    wallet_id

    wallet {
      wallet_hash
      id

     cryptonetwork {
        commission
      }
    }

        user {
      nickname
      id
    }

  }
}






        `,
        result({data}) {
          this.conclusion = data.exchange_withdrawals;
        },
      },
    },
  },


  data: () => ({
    tag: 'test',
    conclusion: [],

    headers: [
      { value: "id", text: "ID" },
      { value: "status", text: "Статус" },
      { value: "nickname" , text: "Никнейм" },
      { value: "userid", text: "Юзер ИД" },
      { value: "created_at", text: "Дата создания" },
      { value: "crypto", text: "Валюта" },
      { value: "amount", text: "Сумма" },
      { value: "commission", text: "Комиссия" },
      { value: "wallet", text: "Кошелек" },
        // { value: "actions", text: "Действия" },
    ],
  }),

  methods: {

    dosnackbar(text) {
      this.$refs.snackbar.showit(text)
    },

    async success( value ) {
      //  /conclusion/success/{conclusion_id}':

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
      };

      let url = `${process.env.VUE_APP_EXCHANGE_API}/conclusion/success/${value.id}`

      fetcher(this.dosnackbar, url, requestOptions)
    },

    async cancel( value , text ) {
      //  /conclusion/cancel/{conclusion_id}':

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify({error_text : text})
      };

      let url = `${process.env.VUE_APP_EXCHANGE_API}/conclusion/cancel/${value.id}`

      fetcher(this.dosnackbar, url, requestOptions)
    },


    amount_usdt(item){
      return item.conversions[0] ? Math.abs(item.conversions[0].balance_usdt_old - item.conversions[0].balance_usdt_now) : 0
    },

    amount_btc(item){
      return item.conversions[0] ? Math.abs(item.conversions[0].balance_btc_old - item.conversions[0].balance_btc_now) : 0
    },

    money_from(item){
      if (item.conversions){
        if (item.conversions[0].convert_to==="USDT")   //from btc
          return this.amount_btc(item) + " BTC"
        else
          return this.amount_usdt(item) + " USDT"
      }else
        return "0"
    },

    money_to(item){
      if (item.conversions){
        if (item.conversions[0].convert_to==="USDT") //from usdt
          return this.amount_usdt(item) + " USDT"
        else
          return this.amount_btc(item) + " BTC"
      }else
        return "0"
    },

    toFixed2(num) {
      return num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
    },

  },

}
</script>
